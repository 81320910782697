  /* .App {
  text-align:right;
} */
.Generation_idea2_free_main {
    justify-content: space-around;
    position: relative;
    display: flex;
    padding-left: 50px;
    padding-right: 50px;   
    padding-top: 50px; 
    font-family: 'Noto Serif';
}

input.form-control.form-control-lg{
    border-color: darkslategrey;
    font-size: 30px;
    
}

div.display-only {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 50px;
    height: 150px;
    font-size: 25px;
    line-height: 1.8;
    border: 2px solid darkslategray;
}

div.display-only p {
    margin: 0px;
    cursor: pointer;
    font-weight: 500;
    color: darkslategray;
}

.display-only span:hover {
    color: #ff5863; /* ホバー時の色 */
  }
  
textarea.form-control {
    height: 160px;
    resize: none;
}
textarea.free-text {
    margin: 10px 0px;
    height: 160px;
    resize: none;
}

.card-tips-main.card {
    background-color: #FFEDB3;
    border: #FFEDB3;
    height: 500px;
}

p.card-text {
    font-size: 25px;
}

p.text-start-subject{
    margin-left: 5%;
    margin-top: 4%;
    font-size: 25px;
}

.free-idea2-Button {
    margin-top: 40px;
    /* padding-left: 20px; */
    text-align: center;
}

.free-idea2-Button1 {
    margin-right: 40px;
    margin-top: 70px;
    width: 250px;
    height: 80px;
    padding: 10px;
    font-size: 30px;
    border-radius: 10px ;
    background: #58abff;
    border: #58abff;
    color: #FFFFFF;
    font-family: 'HGP創英角ﾎﾟｯﾌﾟ体';
}

.free-idea2-Button1:hover,
.free-idea2-Button1:focus {
  background-color: #4588cb; /* hoverやfocus時の色も設定 */
  border-color: #4588cb;
  color: white;
}

.free-idea2-Button2 {
    margin-left: 40px;
    width: 250px;
    height: 80px;
    padding: 10px;
    font-size: 30px;
    border-radius: 10px ;
    background: #F58F98;
    border: #F58F98;
    color: #FFFFFF;
    font-family: 'HGP創英角ﾎﾟｯﾌﾟ体';
}

.free-idea2-Button2:hover,
.free-idea2-Button2:focus {
    background-color: #DE4151; /* hoverやfocus時の色も設定 */
    border-color: #DE4151;
    color: white;
}

.free-text-start-main {
    margin-bottom: 0px;
    font-weight: bold;
}


.free-Note-text {
    margin-top: 50px;
}

.free-Note-text h2 {
    color: #DC143C;
    font-weight: bold;
}

.free-Note-text p{
    font-size: 25px;
    line-height: 1.7;
    border: 2px solid darkslategrey;
    width: 830px;
    height: 200px;
    border-radius:10px;
    overflow-y: scroll;
}


.card-tips-main {
    height: 500px;
    border-radius:10px;
    line-height: 1.9;
    margin-top: 50px;
}
