/* .Gallary-tab {
  justify-content: space-around;
  position: relative;
  display: flex;
  padding-left: 50px;
  padding-bottom: 50px;
  padding-right: 50px;
} */

.Gallary {
  font-family: 'Noto Serif';
}


.Gallary-Image2 {
  margin-top: -17px;
  background-color: #fdd9e5;
  padding-left: 20px;
  padding-right: 20px;
  height: 700px;
  overflow-y: scroll;
}

.GallaryTab {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
  max-width: 1700px; /* 画像を表示する最大幅を指定 */
  /* margin: 0 auto; */
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.GallaryTab img {
  animation: fadeIn 1s ease-in-out; /* 'is' を '1s' に修正 */
  margin: 10px;
  width: 100%;
  object-fit: cover;
}

.returnhome svg{
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 20px;
  width: 85px;
  height: 85px;
  color: darkslategrey;
}

.Gallary-button {
  float: right;
  margin-right: 40px;
  margin-top: 30px;
  width: 240px;
  height: 70px;
  padding: 10px;
  font-size: 30px;
  border-radius: 10px ;
  background: #FDB933;
  border-color: #FDB933;
  font-family: 'HGP創英角ﾎﾟｯﾌﾟ体';
  color: white;
}

.Gallary-button:active,
.Gallary-button:hover,
.Gallary-button:focus {
  background-color: #e6a92e; /* hoverやfocus時の色も設定 */
  border-color: #e6a92e;
  color: white;
}

.Gallary-Image1 {
  justify-content: space-around;
  position: relative;
  padding-left: 50px;
  padding-bottom: 50px;
  padding-right: 50px;
}

/* .Gallary-image1 {
  min-height: 50px;
  min-width: 50px;
  max-width: 200px;
  max-height: 200px;
  margin: 20px;
  float: left;
} */

/* .Gallary-image1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

/* .Gallary-image1 {
  width: 200px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
} */

.mb-3 {
  padding-left: 50px;
}

.mb-3.nav.nav-tabs {
  padding-bottom: 0px;
}

.Gallary-Image.col {
  padding: 20px;
}

.Gallary-Image img{
  height: 100%;
  width: 100%;
}

.show-image-Gallary {
  /* width: 500px;
  height: 500px; */
  margin: auto;
}
.show-image-Gallary img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nav-tabs .nav-link{
  width:  260px;
  height: 60px;
  font-size: 24px;
  font-weight: bold;
  color: darkslategrey;
  background-color: #ffe7ef;
  border: 2px solid #ffe7ef;
}

.nav-tabs .nav-link.active{
  background-color: #fdd9e5;
  border: 2px solid #fdd9e5;
  color: darkslategrey;
}

.nav-tabs {
  border-color: white;
}