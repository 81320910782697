/*CommonHeader.jsのスタイリング*/
header {
    width: 100%;
    padding: 0px 0;
    margin: 0 auto;
    flex: 1;
}

.headline{
    font-size: 40px;
    margin-left: 15px;
}

.headline a {
  color: darkslategrey;
  font-weight: bold;
  text-decoration: none;
}
  
/* header a {
    margin-left: 100px;
} */

.headerGallary {
    display: flex;
    justify-Content: space-between;
    background-color: antiquewhite;
}

.home-Gallary a {
  font-size: 37px;
  margin-right: 15px;
  color: darkslategrey;
  font-weight: bold;
  text-decoration: none;
}