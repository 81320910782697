.parentContainer {
    width: 100%;
    height: 100%; 
}

.selectdouwa{
    padding-top: 10px;
    position: relative;
    font-size: 40px;;
    text-align: left;
    margin-left: 100px;
    font-family: 'Noto Serif';
    color: darkslategrey;
    font-weight: bold;
}

/* .center {
    position: absolute;
    top: 250px;
    left: 190px;
    font-size: 32px;
} */

.waku-idea2 img {
    width: 250px;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 20px;
}

.waku-idea2 p {
    padding-top: 0;
    font-size: 30px;
}

.select-image {
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.select-image:hover {
    border-color: red; /* ホバー時のボーダーカラーを指定 */
}

.selected {
    border-color: #00cc00 !important; /* 選択時のボーダーカラーを指定 */
}

.select-card.card:hover {
    border-color: red;
    border-width: 2px;
}


/* div.card{
    padding: 20px;
    width: 250px;
    height: 250px;
} */

.select-col {
    padding: 20px;
}

.select-col a {
    height: 100%;
}
.select-card.card {
    height: 100%;
    cursor: pointer;
    border-width: 2px;
    background-color: #FFEDB3;
    border-color: #FFEDB3;
}

p.select-card {
    font-size: 30px;
    color: darkslategrey;
}


/* .waku:hover img {
    border: 2px solid black;
}  */

/* div .btn-1 {
    margin: 0;
    float:right;
    margin-right: 100px;
    font-size: 35px;
    background:red;
    border: none;
    width: 200px;
    padding: 10px;
    box-sizing: border-box;
    border-radius:10px;
}

div .btn:hover {
    background-color: red;
} */
