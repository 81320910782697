/*Home.jsのスタイリング*/
.App {
  margin:10px;
  text-align: center;
  padding: 100px;
  margin-left: auto !important;
  font-size: 35px;
  font-weight: bold;
  font-family: 'Noto Serif';
}

.App img{
  width: 450px;
  margin-left: 100px;
  margin-right: 100px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 10px;
}

.App img:hover {
  border-color: red; /* ホバー時のボーダーカラーを指定 */
}

.App input[type=radio]:checked+label::before{
  border: 3px solid #000;
  box-sizing: border-box;
}

.App input[type=radio]{
  display: none;
}

/* ul a {
  font-size: 40px;
  margin-left: 10px;
} */
