.fome-scene-image {
    justify-content: space-around;
    position: relative;
    display: flex;
}

.fome-scene-image p {
    border: 2px solid darkslategrey;
    width: 800px;
    height: 300px;
    margin-top: 100px;
    border-radius:10px;
    font-size: 30px;
    text-align: left;
    font-weight: bold;
    font-family: 'Noto Serif';
}

.image {
    min-height: 200px;
    min-width: 200px;
    padding-top: 100px;
    width: 700px;
    height: 700px;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    display: flex;
}


.image-set1  {
    background-color: #FFEDB3;
    border-color: #FFEDB3;
    padding: 10px;
    border-radius:10px;
}

.image-1 img {
    width: 50%;
    height: 50%;
    object-fit: cover;
    padding: 5px;
    float: left;
    border-radius:10px;
}

.image-2 img {
    width: 50%;
    height: 50%;
    object-fit: cover;
    padding: 5px;
    float: left;
    border-radius:10px;
}

/* .image-set2  {
    justify-content: space-around;
    position: relative;
    display: flex;
    padding-bottom: 50px;
} */

.image-3 img {
    width: 50%;
    height: 50%;
    object-fit: cover;
    padding: 5px;
    float: left;
    border-radius:10px;
}

.image-4 img {
    width: 50%;
    height: 50%;
    object-fit: cover;
    padding: 5px;
    border-radius:10px;
}

.show-image {
    width: 500px;
    height: 500px;
    margin: auto;
}

.show-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius:10px;
}

.modal-5-content  {
    width: 100%;
    height: 100%;
}

.button-container {
    display: flex;
    align-items: center;
    transform: translateY(-40px);
}


.return-btn {
    float:left;
    margin-left: 150px;
    font-size: 35px;
    width: 250px;
    height: 80px;
    padding: 10px;
    font-size: 27px;
    border-radius: 10px ;
    background: #58abff;
    border: #58abff;
    color: #FFFFFF;
    font-family: 'HGP創英角ﾎﾟｯﾌﾟ体';
}

.return-btn:hover,
.return-btn:focus {
    background-color: #4588cb; /* hoverやfocus時の色も設定 */
    border-color: #4588cb;
    color: white;
}

.btn-2 {
    float:left;
    margin-left: 150px;
    font-size: 35px;
    background-color:#F58F98;
    border: none;
    width: 250px;
    height: 80px;
    padding: 10px;
    box-sizing: border-box;
    border-radius:10px;
    font-family: 'HGP創英角ﾎﾟｯﾌﾟ体';
    color: white;
}

.btn-2:hover,
.btn-2:focus {
  background-color: #DE4151; /* hoverやfocus時の色も設定 */
  border-color: #DE4151;
  color: white;
}

.modal-title {
    font-size: 2.0rem;
    font-weight: bold;
    font-family: 'Noto Serif';
    color: darkslategrey;
}

.modal-footer {
    gap: 100px;
    justify-content: center;
}

.btn-3 {
    font-size: 35px;
    background:#58abff;
    border: none;
    width: 250px;
    height: 70px;
    box-sizing: border-box;
    border-radius:10px;
    font-family: 'HGP創英角ﾎﾟｯﾌﾟ体';
    color: white;
}

.btn-3:hover,
.btn-3:focus {
  background-color: #4588cb; /* hoverやfocus時の色も設定 */
  border-color: #4588cb;
  color: white;
}


/* .image-card {
    background-color: #FFEDB3;
    border-color: #FFEDB3;
} */

/* .card-tips-main.card-body {
    width: 100%;
} */