.parentContainer {
    width: 100%;
    height: 100%;  
}

.select-douwa {
    font-weight: bold;
    font-family: 'Noto Serif';
    padding-top: 10px;
    position: relative;
    font-size: 40px;
    text-align: left;
    margin-left: 100px;
}

/* .center {
    position: absolute;
    top: 250px;
    left: 190px;
    font-size: 32px;
} */

.waku img {
    width: 260px;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: 20px;
}

.waku p {
    font-weight: bold;
    font-family: 'Noto Serif';
    text-align:left;
    font-size: 30px;
    padding-left: 100px;
}

.selectable-image {
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 10px;
    margin: 10px;
}

.selectable-image:hover {
    border-color: red; /* ホバー時のボーダーカラーを指定 */
}

/* .waku:hover img {
    border: 2px solid black;
}  */

/* div .btn-1 {
    margin: 0;
    float:right;
    margin-right: 100px;
    font-size: 35px;
    background:red;
    border: none;
    width: 200px;
    padding: 10px;
    box-sizing: border-box;
    border-radius:10px;
}

div .btn:hover {
    background-color: red;
} */
