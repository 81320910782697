.scene p {
    font-size: 35px;
    text-align: left;
    margin-left: 100px;
    padding-top: 10px;
    font-weight: bold;
    font-family: 'Noto Serif';
}

.scene h2 {
    margin-left: 100px;
    text-align: left;
    font-weight: bold;
    font-family: 'Noto Serif';
}

.form-check {
    margin-left: 125px;
    margin-bottom: 15px;
    width: 1000px;
    padding: 5px;
}

.form-check Button{
    font-size: 30px;
    width: 1200px;
    padding: 10px;
    font-family: 'Noto Serif';
}

.text-area{
    margin-top: 50px;
}

.text-area h2 {
    color: #DC143C;
    font-weight: bold;
    font-family: 'Noto Serif';
}

.text-area p{
    font-size: 30px;
    margin-left: 125px;
    border: 2px solid darkslategrey;
	width: 1200px;
	height: 250px;
    padding: 10px 15px;
    border-radius:10px;
    font-weight: 400;
    overflow: hidden;
}

.btn-1 {
    font-family: 'HGP創英角ﾎﾟｯﾌﾟ体';
    color: white;
    float:right;
    margin-right: 200px;
    margin-top: -90px;
    font-size: 35px;
    background-color:#F58F98;
    border-color: #F58F98;
    width: 250px;
    height: 80px;
    padding: 10px;
    box-sizing: border-box;
    border-radius:10px;
}

.btn-1:hover,
.btn-1:focus {
  background-color: #DE4151; /* hoverやfocus時の色も設定 */
  border-color: #DE4151;
  color: white;
}