/*AppDeacription.jsのスタイリング*/
.text-itme-desc {
    padding-top: 40px;
    padding-bottom: 100px;
    margin-left: 100px;
    overflow: hidden;
}

/* .text-itme-desc p {
    font-family: 'Noto Serif';
    font-size: 30px;
    border: 2px solid darkslategrey;
    width: 1300px;
	height: 260px;
    padding: 10px 15px;
    line-height: 1.8;
    border-radius:10px;
    margin-bottom: 2rem;
    overflow-y: scroll;
} */

.text-explanation {
    font-family: 'Noto Serif';
    font-size: 30px;
    border: 2px solid darkslategrey;
    width: 1300px;
	height: 260px;
    padding: 10px 15px;
    line-height: 1.8;
    border-radius:10px;
    margin-bottom: 2rem;
}

.text-Notes {
    font-family: 'Noto Serif';
    font-size: 30px;
    border: 2px solid darkslategrey;
    width: 1300px;
	height: 310px;
    padding: 10px 15px;
    line-height: 1.8;
    border-radius:10px;
    margin-bottom: 2rem;
}

.text-itme-desc h2 {
    font-size: 35px;
    font-family: 'Noto Serif';
    color: darkslategrey;
    font-weight: bold;
}

.text-h2 h2 {
    font-family: 'Noto Serif';
    color: crimson;
    font-weight: bold;
}
  

.btn1 {
    font-family: 'HGP創英角ﾎﾟｯﾌﾟ体';
    color: white;
    float:right;
    margin-right: 100px;
    margin-top: -100px;
    font-size: 35px;
    background:#F58F98;
    border: none;
    width: 250px;
    height: 80px;
    padding: 10px;
    box-sizing: border-box;
    border-radius:10px;
    border-color: pink;
}
.btn1:hover,
.btn1:focus {
    background-color: #DE4151; /* hoverやfocus時の色も設定 */
    border-color: #DE4151;
    color: white;
}