.GeneratedResult_idea2_free_main {
    justify-content: space-around;
    position: relative;
    display: flex;
    padding-left: 50px;
    padding-bottom: 50px;
    padding-right: 50px;
    font-family: 'Noto Serif';
    color: darkslategrey;
}

.free_idea2_Image{
    margin: 10px;
    min-height: 200px;
    min-width: 200px;
    width: 700px;
    height: 700px;
    padding: 20px;
}

.free_Result_idea2_text {
    display:flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius:10px;
    border: 2px solid darkslategrey;
    padding: 10px;
    font-size: 25px;
    max-width: 660px;
    max-height: 160px;
    min-width: 300px;
    min-height: 100px;
    overflow: auto;
}

.free_Result_idea2_text p {
    margin: 0px;
    max-height: 160px;
    width: 100%;
    line-height: 1.6;
}

.ImageSet1 {
    justify-content: space-around;
    position: relative;
    display: flex;
    /* padding-left: 50px;
    padding-bottom: 50px;
    padding-right: 50px; */
}

.Image1 img {
    width: 50%;
    height: 50%;
    object-fit: cover;
    padding: 5px;
    border-radius:10px;
}

.Image2 img {
    width: 50%;
    height: 50%;
    padding: 5px;
    /* margin: 10px; */
    object-fit: cover;
    border-radius:10px;
}

/* .ImgeSet2 {
    justify-content: space-around;
    position: relative;
    display: flex;
    border-radius:10px;
} */
.Image3 img {
    width: 50%;
    height: 50%;
    /* margin: 10px; */
    object-fit: cover;
    padding: 5px;
    border-radius:10px;
}
.Image4 img {
    width: 50%;
    height: 50%;
    object-fit: cover;
    padding: 5px;
    border-radius:10px;
}


.free-Image-Button{
    margin-top: 50px; 
}

.button-container {
    display: flex;
    align-items: center;
    transform: translateX(30px);
}

.return-button {
    margin-top: 30px;
    margin-right: 20px;
    width: 250px;
    height: 80px;
    padding: 10px;
    font-size: 30px;
    border-radius: 10px ;
    border-radius: 10px ;
    background: #58abff;
    border: #58abff;
    color: #FFFFFF;
    font-family: 'HGP創英角ﾎﾟｯﾌﾟ体';
}

.return-button:hover,
.return-button:focus {
    background-color: #4588cb; /* hoverやfocus時の色も設定 */
    border-color: #4588cb;
    color: white;
}

.free-result-idea2-button {
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 30px;
    width: 250px;
    height: 80px;
    padding: 10px;
    font-size: 30px;
    border-radius: 10px ;
    background: #F58F98;
    border: #F58F98;
    color: #FFFFFF;
    font-family: 'HGP創英角ﾎﾟｯﾌﾟ体';
}

.free-result-idea2-button:hover,
.free-result-idea2-button:focus {
    background-color: #DE4151; /* hoverやfocus時の色も設定 */
    border-color: #DE4151;
    color: white;
}

.free-Note-image{
    margin-top: 50px;
    border-color: darkslategrey;
}

.free-card-tips-main {
    background-color: #FFEDB3;
    border: #FFEDB3;
    height: 700px;
    width: 600px;
    border-radius:10px;
}
