.GeneratedResult_idea2_main {
    justify-content: space-around;
    position: relative;
    display: flex;
    padding-left: 50px;
    padding-bottom: 50px;
    padding-right: 50px;
    font-family: 'Noto Serif';
    color: darkslategrey;
}

.idea2-Image{
    margin: 10px;
    min-height: 200px;
    min-width: 200px;
    width: 700px;
    height: 700px;
    padding: 20px;
}

.Result_idea2_text {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius:10px;
    border: 2px solid darkslategrey;
    padding: 10px;
    font-size: 25px;
    max-width: 660px;
    max-height: 160px;
    min-width: 300px;
    min-height: 100px;
}

.Result_idea2_text p {
    width: 100%;
}

.ImageSet-1 {
    justify-content: space-around;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    /* padding-left: 50px;
    padding-bottom: 50px;
    padding-right: 50px; */
}

.ImageSet-1 img {
    width: 300px;
    height: 300px;
    border-radius:10px;
}

.Image-1 img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    padding: 5px;
    border-radius: 10px;
    float: left;
}

.Image-2 img {
    width: 300px;
    height: 300px;
    padding: 5px;
    object-fit: cover;
    border-radius: 10px;
    float: left;
}

/* .ImgeSet2 {
    justify-content: space-around;
    position: relative;
    display: flex;
} */

.Image-3 img {
    width: 300px;
    height: 300px;
    /* margin: 10px; */
    object-fit: cover;
    padding: 5px;
    float: left;
}

.Image-4 img {
    width: 300px;
    height: 300px;
    /* margin: 10px; */
    object-fit: cover;
    padding: 5px;
}


.Image-Button{
    margin-top: 127px; 
}

.Image5 {
    min-width: 50px;
    max-width: 600px;
}

.Image5 img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.Result-Button {
    text-align: center;
}

.button-container {
    display: flex;
    align-items: center;
    transform: translateX(30px);
}

.return-button {
    margin-top: 30px;
    margin-right: 20px;
    width: 250px;
    height: 80px;
    padding: 10px;
    font-size: 30px;
    border-radius: 10px ;
    border-radius: 10px ;
    background: #58abff;
    border: #58abff;
    color: #FFFFFF;
    font-family: 'HGP創英角ﾎﾟｯﾌﾟ体';
}

.return-button:hover,
.return-button:focus {
    background-color: #4588cb; /* hoverやfocus時の色も設定 */
    border-color: #4588cb;
    color: white;
}

.result-idea2-button {
    margin-top: 30px;
    margin-left: 20px;
    width: 250px;
    height: 80px;
    padding: 10px;
    font-size: 30px;
    border-radius: 10px ;
    background: #F58F98;
    border: #F58F98;
    color: #FFFFFF;
    font-family: 'HGP創英角ﾎﾟｯﾌﾟ体';
}

.result-idea2-button:hover,
.result-idea2-button:focus {
  background-color: #DE4151; /* hoverやfocus時の色も設定 */
  border-color: #DE4151;
  color: white;
}

.Image-Button-Card {
    background-color: #FFEDB3;
    border: #FFEDB3;
    border-radius: 10px;
}

.Image-Card {
    background-color: #FFEDB3;
    border-color: #FFEDB3;
    border-radius: 10px;
}
