.Edit{
    justify-content: space-around;
    position: relative;
    display: flex;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
}

.Edit-form{
    padding: 40px;
}

.Edit-form-control {
    max-width: 700px;
    min-width: 350px;
    /* object-fit: cover; */
}

textarea.form-control{
    margin-bottom: 50px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 10px;
    border: 2px solid darkslategrey;
    font-size: 25px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/*童話生成のstyle*/
.Edit-form-check-1 {
    border-radius:10px;
    border: 2px solid darkslategrey;
    padding: 10px;
    margin: 50px;
    min-width: 100px;
    min-height: 3px;
    width: 900px;
    height: 250px;
}

.Edit-form-check-1 label {
    margin-left: 15px;
    margin-top: 10px;
    font-size: 27px;
    /* object-fit: cover; */
}

.Edit-form-check {
    border-radius:10px;
    border: 2px solid darkslategrey;
    padding: 10px;
    margin: 50px;
    min-width: 100px;
    min-height: 3px;
    width: 700px;
    height: auto;
}

.Edit-form-check label {
    margin-left: 10px;
    margin-top: 2px;
    margin-bottom: 4px;
    font-size: 25px;
    /* object-fit: cover; */
}

.Edit_ImageButton {
    padding: 40px;
}

.Edit_Image {
    min-width: 100px;
    max-width: 600px;
}

.Edit_Image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius:10px;
}

.Edit-Button {
    /* margin-top: 50px;
    margin-left: -40px; */
    text-align: center;
}
.Edit-form-Button {
    text-align: center;
}
.Edit-button1 {
    width: 250px;
    height: 80px;
    padding: 10px;
    font-size: 30px;
    border-radius: 10px ;
    background: #58abff;
    border: #58abff;
    color: #FFFFFF;
    font-family: 'HGP創英角ﾎﾟｯﾌﾟ体';
}

.Edit-button1:active,
.Edit-button1:hover,
.Edit-button1:focus {
  background-color: #4588cb; /* hoverやfocus時の色も設定 */
  border-color: #4588cb;
  color: white;
}

.Edit-button2 {
    /* margin-right: 30px;
    margin-left: 10px;
    margin-top: 70px; */
    width: 250px;
    height: 80px;
    padding: 10px;
    font-size: 30px;
    border-radius: 10px ;
    background: #F58F98;
    border: #F58F98;
    color: #FFFFFF;
    font-family: 'HGP創英角ﾎﾟｯﾌﾟ体';
}
.Edit-button2:active,
.Edit-button2:hover,
.Edit-button2:focus {
    background-color: #DE4151; /* hoverやfocus時の色も設定 */
    border-color: #DE4151;
    color: white;
}

.Edit-button3 {
    /* margin-right: 40px; */
    /* margin-left: 0px; */
    width: 250px;
    height: 80px;
    padding: 15px;
    font-size: 30px;
    border-radius: 10px ;
    background: #58abff;
    border: #58abff;
    color: #FFFFFF;
    font-family: 'HGP創英角ﾎﾟｯﾌﾟ体';
}
.Edit-button3:active,
.Edit-button3:hover,
.Edit-button3:focus {
  background-color: #4588cb; /* hoverやfocus時の色も設定 */
  border-color: #4588cb;
  color: white;
}

.Edit_Image-Card {
    background-color: #FFEDB3;
    border: #FFEDB3;
    border-radius:10px;
}

/* .col {
    padding: 20px;
} */

.Edit-col {
    padding: 20px;
}
/* .Edit-col button {
    width: 100%;
} */
/* .col button {
    height: 100%;
} */

.card-text:last-child {
    margin-left: 10px;
}