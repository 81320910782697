  /* .App {
  text-align:right;
} */

.Generation_idea2_main{
    justify-content: space-around;
    position: relative;
    display: flex;
    padding-left: 50px;
    padding-right: 50px;   
    padding-top: 50px;
    font-family: 'Noto Serif';
    color: darkslategrey;
}

input.form-control.form-control-lg{
    border: 2px solid darkslategrey;
    font-size: 30px;
    width: 830px;
}

/* .idea2-order {
    margin-bottom: 0px;
    margin-top: 20px;
    margin-left: 40px;
    font-size: 25px;
} */

.generated-image.Card {
    width: 100%;
}

.Image01 {
    text-align: center;
    min-height: 50px;
    min-width: 50px;
    width: 600px;
    height: 550px;
    margin-top: 20px;
}

.Image01 img {
    width: 500px;
    height: 500px;
    object-fit: cover;
    border-radius: 10px;
}

.idea2-Button {
    margin-top: 40px;
    /* padding-left: 20px; */
    text-align: center;
}

.idea2-button1 {
    margin-right: 40px;
    width: 250px;
    height: 80px;
    padding: 10px;
    font-size: 27px;
    border-radius: 10px ;
    background: #58abff;
    border: #58abff;
    color: #FFFFFF;
    font-family: 'HGP創英角ﾎﾟｯﾌﾟ体';
}

.idea2-button1:hover,
.idea2-button1:focus { 
  background-color: #4588cb; /* hoverやfocus時の色も設定 */
  border-color: #4588cb;
  color: white;
}

.idea2-button2 {
    margin-left: 40px;
    width: 250px;
    height: 80px;
    padding: 10px;
    font-size: 30px;
    border-radius: 10px ;
    background: #F58F98;
    border: #F58F98;
    color: #FFFFFF;
    font-family: 'HGP創英角ﾎﾟｯﾌﾟ体';
}

.idea2-button2:hover,
.idea2-button2:focus {
    background-color: #DE4151; /* hoverやfocus時の色も設定 */
    border-color: #DE4151;
    color: white;
}

.text-start-main {
    margin-top: 10px;
    margin-bottom: 0px;
    font-weight: bold;
}


.generated-image.card{
    background-color: #FFEDB3;
    border-color: #FFEDB3;
    /* width: 100%;
    padding-left: 40px;
    padding-right: 50px; */
    border-radius: 10px;
}

.card-title {
    margin-left: 50px;
}

.Note-text {
    margin-top: 30px;
}

.Note-text h2 {
    color: #DC143C;
    font-weight: bold;
}

.Note-text p{
    font-size: 25px;
    line-height: 1.7;
    border: 2px solid darkslategrey;
    width: 830px;
    height: 175px;
    border-radius:10px;
    overflow-y: scroll;
}